// extracted by mini-css-extract-plugin
export var main = "celbrate-module--main--3CL2T";
export var links = "celbrate-module--links--1LqBm";
export var column = "celbrate-module--column--1i-UM";
export var skills = "celbrate-module--skills--2VhqG";
export var skill = "celbrate-module--skill--3gnra";
export var skillHeader = "celbrate-module--skill-header--2LQp2";
export var time = "celbrate-module--time--1VDyf";
export var title = "celbrate-module--title--2FXlS";
export var skillImage = "celbrate-module--skill-image--3gO3u";
export var contact = "celbrate-module--contact--1uVS_";
export var intro = "celbrate-module--intro--2dZ5a";
export var text = "celbrate-module--text--2fj8f";
export var profilePictureWrapper = "celbrate-module--profile-picture-wrapper--16Cvo";
export var profilePicture = "celbrate-module--profile-picture--3_lgt";