import React, {useLayoutEffect, useRef} from "react";
import Seo from "../../components/seo/seo";
import Nav from "../../components/nav/nav";
import * as styles from "./celbrate.module.scss";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {gsap} from "gsap";
import {Link} from "gatsby";

const CelbratePage = ({data}) => {
  const text = useRef();
  const picture = useRef();
  const intro = useRef();

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: intro.current,
        start: "top top",
        end: `+=1000`,
        markers: false,
        scrub: true,
        pin: true,
        invalidateOnRefresh: true
      },
    })

    tl.fromTo(intro.current, {
      backgroundPosition: "0",
    }, {
      backgroundPosition: "100%"
    });

    tl.fromTo(text.current, {
      x: 0,
    }, {
      x: -(text.current.scrollWidth) + window.innerWidth - 100
    }, "<");

    return () => {
      tl?.kill();
    };
  }, []);

  return (
    <>
      <Seo whiteBody={true} />
      <Nav />

      <div>
        <div className={styles.intro} ref={intro}>
          <div className={styles.profilePictureWrapper} ref={picture}>
            <StaticImage
              src="../../images/celbrate-preview.png"
              alt="app visualization"
              className={styles.profilePicture}
              objectPosition={"top center"}
              loading={"eager"}
              objectFit={"contain"}
            />
          </div>

          <div className={styles.text} ref={text}>
            <h2>Simple</h2>
            <h2>Elegant</h2>
            <h2>Useful</h2>
          </div>
        </div>
      </div>

      <main className={styles.main}>
        <section>
          <h1>What is it?</h1>
          <div>
            <p>This app helps you track important dates such as birthdays and name days.
            </p>
          </div>
        </section>
        <section>
          <h2>Why is it special</h2>
          <div>
            <p>It has been developed with simplicity in mind. To allow easy use, it has a feature to quickly import
              contacts right from your phone contact list and detect their name days. If you have set birthdays in there,
              those get imported automatically as well.</p>
          </div>
        </section>
        <section>
          <h2>Widgets</h2>
          <div>
            <p>Nobody wants to have to open the app every day to see the name day or upcoming birthdays. That is why
            I've added widgets which you can add to your home screen or lock screen (on iOS 16 and higher).</p>
          </div>
        </section>
        <section className={styles.links}>
          <Link to={"/celbrate/privacy-policy"}>Privacy Policy</Link>
        </section>
        <section data-navigation-id="contact" className={styles.contact}>
          <h3><a href="mailto:martinledl.tech@gmail.com">martinledl.tech@gmail.com</a></h3>
          <h3><a href="tel:+420 777 803 934">+420 777 803 934</a></h3>
        </section>
      </main>
    </>
  )
}

export default CelbratePage;
